<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div v-if="!capabilityModal" class="popup" id="popup-educourse-e01">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="course-summary">
              <article class="course-card">
                <div class="course-image">
                  <a href="javascript:" class="image-link"></a>
                  <figure class="image-figure">
                    <img v-if="params.thumb" :src="getImg(params.thumb)" alt="">
                    <img v-else-if="params.mstThumb" :src="getImg(params.mstThumb)" alt="">
                    <CourseThumb v-else :num="params.crseMstSn"/>
                  </figure>
                </div>
                <div class="course-content">
                  <div class="content-header">
                    <p class="title-label">수강신청 정보</p>
                    <h4 class="title"><a href="javascript:" class="title-link">{{ params.crseNm }}</a></h4>
                  </div>
                  <div class="content-meta">
                    <span class="text">{{ params.orgnzNm }}</span>
                    <span class="text">{{
                        timestampToDateFormat(params.bgngDt, 'yyyy.MM.dd.w')
                      }} - {{ timestampToDateFormat(params.endDt, 'yyyy.MM.dd.w hh:mm') }}</span>
                    <span class="text">{{ params.crseMlg > 0 ? `마일리지 ${params.crseMlg}` : '-' }}</span>
                    <!--                    <span class="text">마일리지 {{ params.crseMlg }}</span>-->
                  </div>
                  <div v-if="params.rtrcnPsbltYn === 'Y'" class="content-notice">
                    <span class="text">{{ timestampToDateFormat(params.rtrcnEndDt, 'yyyy.MM.dd hh:mm') }} 까지 취소가능</span>
                  </div>
                </div>
                <!--                <div class="course-icons">-->
                <!--                  <div class="icons">-->
                <!--                    <i class="icon-course-collective" title="집합연수"></i>-->
                <!--                    <i class="icon-course-cyber" title="사이버"></i>-->
                <!--                    <i class="icon-course-book" title="교재"></i>-->
                <!--                  </div>-->
                <!--                </div>-->
              </article>
            </div>
            <div class="course-enrollments">
              <!-- popup-section:선택차수 -->
              <section class="popup-section">
                <header class="section-header">
                  <h4 class="title">선택차수</h4>
                </header>
                <div class="section-content">
                  <div class="kb-table kb-table-striped">`
                    <table>
                      <colgroup>
                        <col style="width:auto;">
                        <col style="width:250px">
                        <col v-if="!isView" style="width:250px">
                        <!--                        <col style="width:250px">-->
                        <col style="width:210px">
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">과정차수명</span></th>
                        <th><span class="th-title">연수기간</span></th>
                        <th v-if="!isView"><span class="th-title">신청기간</span></th>
                        <!--                        <th><span class="th-title">동시평가일</span></th>-->
                        <th><span class="th-title">신청정원</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><p class="td-text">{{ params.crseNm }}</p></td>
                        <td><p class="td-text">{{ timestampToDateFormat(params.bgngDt, 'yyyy.MM.dd') }} ~
                          {{ timestampToDateFormat(params.endDt, 'yyyy.MM.dd') }}</p></td>
                        <td v-if="!isView"><p class="td-text">{{
                            timestampToDateFormat(params.aplyBgngDt, 'yyyy.MM.dd')
                          }} ~ {{ timestampToDateFormat(params.aplyEndDt, 'yyyy.MM.dd') }}</p></td>
                        <!--                        <td><p class="td-text">-</p></td>-->
                        <!--                        <td><p class="td-text">200명</p></td>-->
                        <td><strong class="td-text">{{
                            params.peoplCnt > 0 ?
                                `${numberComma(params.peoplCnt)}명` :
                                '-'
                          }}</strong></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <!-- popup-section:시간외 근무과정안내 -->
              <CourseConsent
                  v-if="consents.length > 0"
                  v-model="checked"
                  :consents="consents"
                  :is-view="isView"
                  :con-params="params"
              />
            </div>
          </div>

          <article v-if="params.bookYn == 'Y'" class="content-section section-divider">
            <header class="section-header">
              <h4 class="title">배송지 정보</h4>
            </header>
            <div class="kb-table-bottom">
              <div class="bottom-notice">
                <i class="icon-notice"></i>
                <strong class="subtext" style="color:red">해당 과정은 교재가 제공되는 과정입니다. 아래의 배송지로 교재가 발송 될 예정이오니, 배송지가 변경되었을 경우
                  배송지 수정하기 버튼을 눌러 수정 바랍니다.</strong>
              </div>
            </div>
            <br/>
            <div class="contact-table">
              <div class="kb-table kb-table-bordered">
                <table>
                  <colgroup>
                    <col style="width:60px;"/>
                    <col/>
                    <col style="width:186px;"/>
                  </colgroup>
                  <tbody>
                  <tr>
                    <td><strong class="td-text ">{{ shipInfo[0].shipZip }}</strong></td>
                    <td><span class="td-text">{{ shipInfo[0].shipAddr }} {{ shipInfo[0].shipDtlAddr }}</span></td>
                    <td class="td-end">
                      <router-link to="/my/profile" class="kb-btn kb-btn-primary kb-btn-sm rounded-lg"><span class="text">배송지 수정하기</span>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </article>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">닫기</span></button>
            <button v-if="!isView" class="kb-btn kb-btn-primary kb-btn-lg" @click="confirmApply"><span class="text">확인</span>
            </button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>

    <div v-else class="popup" id="popup-educourse-e01">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="course-enrollments">
              <!-- popup-section:선택차수 -->
              <!-- popup-section:시간외 근무과정안내 -->
              <section v-if="courseDivCd.includes('舊직무필수')" class="popup-section">
                <header class="section-header"><h4 class="title">현재 신청하시는 과목은 직무 필수과정 <span class="fw-bolder text-blue">[{{ courseDivCd }}]</span>의 <span class="fw-bolder text-red">[{{ params.crseNm }}]</span> 입니다.<br/><br/>

                  해당 과목은 舊.직무 필수과정 과목으로<br/>
                  <span class="fw-bolder text-green">동일 과목 재수강의 목적으로만 신청 가능합니다.</span><br/><br/>

                  아래 이수 이력을 확인하신 후 수강신청 확정하시길 바랍니다.<br/>
                  ※ 재수강 가능기간 : 2024년,2025년(2년 간) / 1과목 당 1회 재수강 가능
                </h4></header>
              </section>
              <section v-else class="popup-section">
                <header class="section-header"><h4 class="title">현재 신청하시는 과목은 직무 필수과정 <span class="fw-bolder text-blue">[{{ courseDivCd }}]</span>의 <span class="fw-bolder text-red">[{{ params.crseNm }}]</span> 입니다.<br/><br/>

                  [공통A], [공통B], [선택]  中 해당 직급의 이수 필요개수를 채운 부문에서<br/>
                  추가 과목을 이수할 경우 ‘직무 필수과정’이 아닌 ‘일반 연수이력’으로 인정됩니다.<br/><br/>

                  아래 이수 이력을 확인하신 후 수강신청 확정하시길 바랍니다.
                </h4></header>
              </section>

            </div>
            <CapabilityGradeTable
                v-if="elements.includes('R')"
                :div-cds="elements"
                :items="gradeItems"
                :result="result"
                :disp-div="'reqsubjOnly'"
            />
            <CourseConsent
                v-if="courseDivCd.includes('舊직무필수')"
                v-model="checkedJikmu"
                :consents="consentsJikmu"
                :is-view="isView"
                :con-params="params"
            />
          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">닫기</span></button>
            <button v-if="!isView" class="kb-btn kb-btn-primary kb-btn-lg" @click="confirmApply"><span class="text">수강신청</span>
            </button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>


import {useAlert} from '@/assets/js/modules/common/alert';
import CourseThumb from '@/components/apply/CourseThumb';
import {
  getResult,
  isSuccess,
  timestampToDateFormat,
  numberComma,
  lengthCheck,
  setParams,
  getItem, getItems
} from '@/assets/js/util';
import CourseConsent from '@/components/common/CourseConsent';
import {computed, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_INSERT_CRSE_REG_APLY} from '@/store/modules/course/course';
import {useRouter} from 'vue-router';
import {applyCourse, crseMndCertCd, pnslCd} from '@/assets/js/modules/course/course-common';
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";
import {
  ACT_GET_ABILITY_REQSUBJ_LIST,
  ACT_GET_ABILITY_REQUIREMENT_LIST,
  ACT_GET_ABILITY_RESULT, MUT_SET_ABILITY_REQSUBJ_LIST
} from "@/store/modules/my/my";
import CapabilityGradeTable from '@/components/capability/CapabilityGradeTable';

export default {
  name: 'ApplyTrainModal',
  components: {CourseConsent, CourseThumb, CapabilityGradeTable},
  props: {
    modelValue: Boolean,
    params: Object,
    isView: Boolean,
    lrnerObjs: Array,
    learns: Array,
    licenses: Array,
    objItems: Array,
    exItems: Array,
    shipInfo: Array,
  },
  emits: ['update:modelValue', 'showSurvey'],
  setup(props, {emit}) {
    const router = useRouter();
    const store = useStore();
    const {showConfirm, showMessage, showLoading, hideLoading} = useAlert();
    const session = computed(() => store.state.auth.session);

    const isLoading = ref(false);
    const checked = ref([]);
    const checkedJikmu = ref([]);
    // const consents = ['overtime', 'personal', 'communication', 'smart', 'training', 'retake', 'stay];
    const consents = [];
    const consentsJikmu = ['training_jikmu'];
    if (props.params.unfiRmbrAmt > 0) {
      consents.push('training');
    }
    if (props.params.stayAplyRcptYn == 'Y') {
      consents.push('stay');
    }
    if (props.params.mediaCateNm.startsWith('통신') && props.params.orgnzNm.startsWith('금융연수원')) {
      consents.push('communication');
    }
    if (crseMndCertCd.includes(props.params.befCrseCd)) {
      consents.push('overtime');
    }
    if (pnslCd.includes(props.params.orgnzCdDcd)) {
      if (props.params.splCrseTyCdDcd === '2066030') {
        consents.push('global');
      } else {
        consents.push('personal');
      }
    }
    const grpExist = ref(false);
    const reApply = ref(false);
    const dupExist = ref(false);
    const grpDate = ref(0);
    const lifeInsr = ref(false);
    const lossInsr = ref(false);
    const jesam = ref(false);

    const capabilityModal = ref(false);

    const result = reactive({
      ficjkwbh: '',
      ficjkgup: '',
      jikmuReqCount: 0,
      jkgupReqBegin: 0,
      jkgupReqGrade: 0,
      jkgupReqEtest: 0,
      selfScore: 0,
      totalScore: 0,
      ficbujum: '',
      ficjikmu: '',
      ficjkwee: '',
      fidhjgsg: '',
      fidhssbr: '',
      jikmuReqCountDefault: 0,
      jikmuReqDefCount: 0,
      modifyDate: null,
      cdpResultId: '',
      createDate: null,
      addscoreLic: 0,
      addscoreSales: 0,
      addscoreEtc: 0,
      pilsupassCnt: 0,
      jkgupGradeBookingId: 0,
      jkgupGradeLrnMapngRsltSumupSn: 0,
      preclassname: '',
      l1PromotionYn: '',
    });

    const items = reactive({
      R: [],
    });

    const reqsubjGroup = reactive([]);
    const reqDivSubjList = ref(null);

    const courseDivCd = ref(null);

    const gradeItems = computed(() => [...items.R]);

    const getAbilityRequirementList = (key) => {
      // searchType => R : 직무필수, O : 신임과정
      let pObj,sObj;
      if(key==="R") {
        pObj = {
          name: "공통",
          maxCnt: 2,
          curCnt: 0
        };
        sObj = {
          name: "선택",
          maxCnt: result.jikmuReqCountDefault - 2,
          curCnt: 0
        };
      }

      store.dispatch(`my/${ACT_GET_ABILITY_REQUIREMENT_LIST}`, {
        cdpResultId: result.cdpResultId,
        params: {searchType: key},
      }).then(res => {
        if(lengthCheck(res)){
          items[key] = getItems(res);
          //신버전 직무필수 수료현황 update
          if(key==="R"){
            let pCnt = items[key].filter( x=> x.gjCdp.indexOf("공통")>=0 ).length;
            pObj.curCnt = pCnt;
            let sCnt = items[key].filter( x=> x.gjCdp.indexOf("선택")>=0 ).length;
            sObj.curCnt = sCnt;

            reqsubjGroup.push(pObj);
            reqsubjGroup.push(sObj);
          }
        }else{
          if(key==="R"){
            reqsubjGroup.push(pObj);
            reqsubjGroup.push(sObj);
          }
        }

      }).catch(err => {
        console.error(err)
      })
    }

    const getAbilityReqSubjList = () => {
      let output = [];
      store.dispatch(`my/${ACT_GET_ABILITY_REQSUBJ_LIST}`, {
        cdpResultId: result.cdpResultId,
        params: {},
      }).then(res => {

        if(lengthCheck(res)){
          output = getItems(res);

          if (output.find(x => x.crseMstSn === props.params.crseMstSn)) {
            courseDivCd.value = output.find(x => x.crseMstSn === props.params.crseMstSn).courseDivCd; // 여기서부터 작업

            if (courseDivCd.value.includes('직무필수-선택')) {
              courseDivCd.value = '직무필수-선택'
            }
          }
        }
      }).catch(err => {
        console.error(err)
      }).finally(()=>{
            reqDivSubjList.value =  output;
            store.commit(`my/${MUT_SET_ABILITY_REQSUBJ_LIST}`, output);
          }
      );
    }

    const getMyAbility = () => {
      store.dispatch(`my/${ACT_GET_ABILITY_RESULT}`).then(res => {
        if (lengthCheck(res)) {
          setParams(result, getItem(res));
          getAbilityReqSubjList();
          getAbilityRequirementList("R");
        }
        readies.result = true;
        // menuView.value = 'overallResult';
      }).catch(e => {
        console.error(e);
      })
    }

    getMyAbility();

    const readies = reactive({
      result: false
    });

    const elements = computed(() => {
      const contains = [];
      const ficjkgup = result.ficjkgup && result.ficjkgup.length > 2 ? result.ficjkgup.substring(0, 2) : result.ficjkgup;
      if (['L0', 'L1', 'S1'].includes(ficjkgup)) {
        // S => 은실종
        contains.push('S');

        // L0 => L1 진급자
        if (result.l1PromotionYn === 'Y') contains.push('O');
      }

      if (['L2', 'L3', 'S2', 'S3'].includes(ficjkgup)) {
        // O => 신임
        contains.push('O');
      }

      // if (['L0', 'L1', 'L2', 'L3'].includes(ficjkgup)) {

      // 2023-02-20 구시스템 처럼 S 직군도 직무필수를 보여주지만 dash-line 처리
      if (['S1', 'S2', 'S3', 'L0', 'L1', 'L2', 'L3'].includes(ficjkgup)) {
        // R => 직무필수
        contains.push('R');
      }
      if (['L0', 'L1', 'L2', 'L3', 'S1', 'S2', 'S3'].includes(ficjkgup)) {
        // P => 승격예비
        contains.push('P');
      }

      return contains;

    });


    const validateAply = () => {
      const currentDate = new Date();

      let result = false;
      let msg = '';



      // 파생상품투자권유자문인력보수(2022년4차) 임시
      if (capabilityModal.value == true && consentsJikmu.length === checkedJikmu.value.length && courseDivCd.value.includes('舊직무필수')) {
        result = true;
      } else if (capabilityModal.value == true && consentsJikmu.length !== checkedJikmu.value.length && courseDivCd.value.includes('舊직무필수')) {
        msg = '모든 항목이 체크되었는지 확인해주세요.';
      } else if (consents.length !== checked.value.length) {
        msg = '모든 항목이 체크되었는지 확인해주세요.';
      } else if (props.params.aplyBgngDt > currentDate.getTime()) {
        msg = '수강신청 기간이 아닙니다.';
      } else if (props.params.aplyEndDt < currentDate.getTime()) {
        msg = '수강신청 기간이 아닙니다.';
      } else {
        result = true;
      }

      // 집합평가객체가 동일한 일자를 2개이상 가지고 있는 경우
      const grpEvlExist = props.lrnerObjs.filter(x => x.grpEvlCnt >= 2);
      if (grpEvlExist.length > 0) {
        const sameGrpEvlDate = grpEvlExist.filter(x => props.objItems.some(y => y.objBgngDt === x.objBgngDt));
        if (sameGrpEvlDate.length > 0) {
          grpExist.value = true;
          grpDate.value = timestampToDateFormat(sameGrpEvlDate[0].objBgngDt, 'yyyy.MM.dd');
        }
      }

      // 사이버평가객체가 동일한 일자를 2개이상 가지고 있는 경우
      const elseEvlExist = props.lrnerObjs.filter(x => x.gnrlEvlCnt >= 2);
      if (elseEvlExist.length > 0) {
        const sameElseEvlDate = elseEvlExist.filter(x => props.objItems.some(y => y.objBgngDt === x.objBgngDt));
        if (sameElseEvlDate.length > 0) {
          result = false;
          msg = '평가일자가 ['.concat(timestampToDateFormat(sameElseEvlDate[0].objBgngDt, 'yyyy.MM.dd'), '] 로 ' +
              '동일한 과정을<br>이미 2개 과정 신청하셨으므로<br>추가신청이 불가합니다.');
        }
      }

      const existed = props.learns.filter(x => x.crseMstSn === props.params.crseMstSn);
      if (existed.length > 0) dupExist.value = true;

      const finalDt = new Date(
          Math.max(
              ...props.learns
                  .filter(x => x.crseMstSn === props.params.crseMstSn)
                  .map(element => {
                    return new Date(element.endDt);
                  }),
          ),
      ).setMonth(props.params.rtakeCrsePsbltYy === '0Y' ? 12 :
          props.params.rtakeCrsePsbltYy === '1Y' ? 24 :
              props.params.rtakeCrsePsbltYy === '2Y' ? 36 :
                  props.params.rtakeCrsePsbltYy === '3Y' ? 48 : 0
      );

      if (props.params.dpcnRegCrseYn === 'Y') {
        if (dupExist.value) {
          if (timestampToDateFormat(currentDate.getTime(), 'yyyy') >= timestampToDateFormat(finalDt, 'yyyy')) {
            reApply.value = true;
          } else {
            result = false;
            msg = '재수강 가능기간이 아닙니다.';
          }
        }
      } else {
        if (dupExist.value) {
          result = false;
          msg = '본 과정은 이미 진행중(신청중)이거나<br>수료한 과정으로 재신청 하실 수 없습니다.';
        }
      }

      // 생명보험 신청, 생명+제3 이력이 없을시
      if (props.params.crseMstSn === 3092) {
        const otherLifeExisted = props.learns.filter(x => x.crseMstSn === 10955 || x.crseMstSn === 10956)
        if (otherLifeExisted.length === 0) {
          lifeInsr.value = true;
        }
      }
      // 손보 신청, 손보+제3 이력이 없을시
      if (props.params.crseMstSn === 3093) {
        const otherLossExisted = props.learns.filter(x => x.crseMstSn === 10956 || x.crseMstSn === 10955)
        if (otherLossExisted.length === 0) {
          lossInsr.value = true;
        }
      }

      if (!result) {
        showMessage(msg);
      }

      return result;
    };

    const confirmApply = () => {
      if (isLoading.value) return;
      isLoading.value = true;

      if (validateAply()) {
        let cMsg = '';

        if (props.licenses.filter(x => x.lcsnCd === 'WR77'|| x.lcsnCd === 'WR78').length !== 0 ) { //제3보험 자격증 여부
          jesam.value = true;
        }

        if (grpExist.value) {
          cMsg += '집합평가일자가 ['.concat(grpDate.value, '] 로 동일한 과정을<br>이미 2개 이상 신청하셨습니다.<br>' +
              '연수신청은 가능하나 수료에 차질이없는지<br>신중하게 결정하시기 바랍니다.<br>');
        }
        if (props.params.crseMlg > 0 && reApply.value) {
          cMsg += '본 연수과정은 현 직급에서 기수료한 과정으로,<br>중복수강을 하는 경우 마일리지가 부여되지 않습니다.<br>';
        }
        if (lifeInsr.value && !jesam.value) {
          cMsg += ' 제3보험 대리점 자격이 없습니다.<br>추후 제3보험 대리점 과정은 보험연수원을 통해<br>개인부담으로 이수하셔야 하므로,<br> 생명+제3보험을 신청해주시기 바랍니다.<br>';
        }
        if (lossInsr.value && !jesam.value) {
          cMsg += ' 제3보험 대리점 자격이 없습니다.<br>추후 제3보험 대리점 과정은 보험연수원을 통해<br>개인부담으로 이수하셔야 하므로,<br> 손해+제3보험을 신청해주시기 바랍니다.<br>';
        }

        if (props.params.classCateSn == 9 && session.value.viceMgrApptYmd != null) {
          cMsg += session.value.lrnerNm + '님은 부점장 임용일이 존재하여<br><span style=color:#FF0000!important;>직무필수 대상이 아니므로,</span><br>단순 교육 목적으로만 수강 가능합니다.<br>'
        }


        // checkYn
        if (props.params.cntstCrseYn === 'Y' || props.params.splCrseTyCdDcd ==='2066030') {
          checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발부<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, () => {

            store.dispatch(`course/${ACT_INSERT_CRSE_REG_APLY}`,
                {distCrseSn: props.params.distCrseSn, params: {checkYn: 'Y'}}).then(res => {
              if (isSuccess(res)) {
                emit('showSurvey');
                // 해당 과정의 설문이 필요합니다.
                closeModal();
              } else {
                showMessage(getResult(res).message);
                isLoading.value = false;
              }
            }).catch(e => {
              console.error(e);
              showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
              isLoading.value = false;
            });

          });
        } else {
          if (props.params.classCateSn == 9 && capabilityModal.value == false && session.value.viceMgrApptYmd == null) {
            capabilityModal.value = true;
            isLoading.value = false;
          } else {
            showConfirm({
              title: '수강신청',
              text: cMsg.concat('수강신청을 하시겠습니까?'),
              callback: () => {
                showLoading();
                applyCourse(
                    props.params.distCrseSn,
                    {},
                    () => {
                      hideLoading();

                      if(props.params.splCrseTyCdDcd=== '2066030'){
                        showMessage('수강신청이 완료되었습니다.');
                        router.push({name: 'TrainGlobal'});
                      }else {
                        showMessage('수강신청이 완료되었습니다.');
                        router.push({name: 'ApplyStatus'});
                      }
                      closeModal();
                    },
                    (res) => {
                      hideLoading();
                      // 수료시 신청불가 연수이력 존재시 에러코드
                      if (getResult(res).number === 319) {
                        const dupCrse = props.learns.filter(x => props.exItems.some(
                            y => y.regCrseCondCdDcd === '2111001' && x.crseMstSn === y.crseMstSn));
                        if (dupCrse.length > 0) {
                          showMessage(
                              getResult(res).message.concat('<br>[', dupCrse[0].crseNm, ']'));
                        } else {
                          showMessage(getResult(res).message);
                        }
                      } else
                          // 취득시 신청불가 자격증 존재시 에러코드
                      if (getResult(res).number === 320) {
                        const dupLcsn = props.licenses.filter(
                            x => props.exItems.some(y => y.regCrseCondCdDcd === '2111002' && x.lcsnCd === y.lcsnCd));
                        if (dupLcsn.length > 0) {
                          showMessage(
                              getResult(res).message.concat('<br>[', dupLcsn[0].lcsnCd, ':', dupLcsn[0].lcsnNm, ']'));
                        } else {
                          showMessage(getResult(res).message);
                        }
                      } else {
                        showMessage(getResult(res).message);
                      }
                      isLoading.value = false;
                    },
                    () => {
                      hideLoading();
                      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                      isLoading.value = false;
                    },
                );
                hideLoading();
              },
              closeCallback: () => {
                isLoading.value = false;
              },
            });
          }
        }
      } else {
        isLoading.value = false;
      }
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const getImg = (src) => {
      return `${process.env.VUE_APP_CLOUDFRONT_URL}/${src}`;
    };

    return {
      checked,
      checkedJikmu,
      consents,
      consentsJikmu,
      confirmApply,
      closeModal,
      timestampToDateFormat,
      numberComma,
      getImg,
      result,
      elements,
      readies,
      capabilityModal,
      gradeItems,
      courseDivCd
    };

  },
};
</script>